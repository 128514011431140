const { default: axios } = require("axios");
import _ from "lodash";
import { setCustomerCart, getCustomerCart } from "./helpers";

(function ($) {
    const body = $("body");

    // ********** start function ***************
    function loadingShoppingCart(cart) {
        const shoppingCart = $(`.shopping-cart`);
        if (shoppingCart.length && !_.isEmpty(cart)) {
            const total_items = cart.total_items;
            const items = cart.items;
            const shippingCharge = cart.shipping_charge;
            const currency = cart.currency;
            let subtotal = 0;
            if (_.isArray(items)) {
                const htmlCartItem = items.map((item) => {
                    let discount_price = item.discount_price;
                    let sale_price = item.sale_price;
                    let quantity = item.quantity;
                    let price = discount_price ? discount_price : sale_price;
                    subtotal += price * quantity;
                    return `<tr>
                          <td class="product-thumbnail">
                          <a href="/product/${item.sku}"><img src="${
                        item.image
                    }" alt="product1">
                          </a>
                          </td>
                          <td class="product-name" data-title="Product">
                          <a href="/product/${item.sku}">${item.name}</a>
                          </td>
                          <td class="product-price" data-title="Price">${
                              currency + price
                          }</td>
                          <td class="product-quantity" data-title="Quantity">
                            <div class="cart-product-quantity">
                              <div class="input-group input-group-sm ">
                                <div class="input-group-prepend">
                                  <button type="button" class="btn btn-default btn-minus" title="Minus Quantity">
                                    <i class="fas fa-minus"></i>
                                  </button>
                                </div>
                                <input type="text" name="quantity" data-item-id="${item.id}" value="${quantity}" title="Quantity" class="quantity form-control" size="4" readonly="readonly">
                                <div class="input-group-append">
                                  <button type="button" class="btn btn-default btn-plus" title="Plus Quantity">
                                    <i class="fas fa-plus"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td class="product-subtotal" data-title="Total">${
                              currency + item.quantity * price
                          }</td>
                          <td class="product-remove" data-title="Remove"><a href="#"><i class="ti-close"></i></a></td>
                        </tr>`;
                });
                $("#shoppingCartTbody").html(htmlCartItem);
                $(".product-subtotal").html(currency + " " + subtotal);
                $(".product-shipping").html(currency + " " + shippingCharge);
                $(".product-grossTotal").html(
                    currency + " " + (shippingCharge + subtotal)
                );
            }
        }
    }

    function loadingShoppingAddress(cart) {
        const shippingAddress = cart.shipping_address;
        const shippingForm = $("#save-shipping-address");
        if (_.isObject(shippingAddress) && shippingForm.length > 0) {
            shippingForm.find("#name").val(shippingAddress.name);
            shippingForm.find("#phone").val(shippingAddress.phone);
            shippingForm.find("#city").val(shippingAddress.city);
            shippingForm.find("#postcode").val(shippingAddress.postcode);
            shippingForm.find("#address").val(shippingAddress.address);
        }
    }

    function loadingCheckoutPage(cart) {
        const shoppingCart = $(`.checkout`);
        if (shoppingCart.length && !_.isEmpty(cart)) {
            const items = cart.items;
            const shippingCharge = cart.shipping_charge;
            const currency = cart.currency;
            let subtotal = 0;
            if (_.isArray(items)) {
                const htmlCartItem = items.map((item) => {
                    let discount_price = item.discount_price;
                    let sale_price = item.sale_price;
                    let quantity = item.quantity;
                    let price = discount_price ? discount_price : sale_price;
                    subtotal += price * quantity;
                    return `<tr>
                          <td class="product-thumbnail">
                          <a href="/product/${item.sku}"><img src="${
                        item.image
                    }" alt="product1">
                          </a>
                          </td>
                          <td class="product-name" data-title="Product"><a href="/product/${
                              item.sku
                          }">${item.name}</a>
                          </td>
                          <td class="product-price" data-title="Price">${
                              currency + price
                          }</td>
                          <td class="product-quantity" data-title="Quantity">${quantity}</td>
                          <td class="product-subtotal" data-title="Total">${
                              currency + item.quantity * price
                          }</td>
                        </tr>`;
                });
                $("#checkoutProducts").html(htmlCartItem);
                $(".subtotal").html(currency + " " + subtotal);
                $(".shippingCharge").html(currency + " " + shippingCharge);
                $(".grossTotal").html(
                    currency + " " + (shippingCharge + subtotal)
                );
            }
        }
    }

    function updateProductDetailsPage(items) {
        const productPage = $(".activeProduct");
        const activeItemId = productPage.attr("data-product-id");
        if (_.isArray(items)) {
            const findActiveProduct = items.find(
                (find) => find.id === parseInt(activeItemId)
            );
            if (!_.isEmpty(findActiveProduct)) {
                productPage.find(".quantity").val(findActiveProduct.quantity);
                productPage.find(".btnAddToCart").addClass("disabled");
                productPage.find(".btnBuyNow").removeClass("disabled");
            }
        }
    }

    function renderCustomerCart(cart) {
        if (!_.isEmpty(cart)) {
            const total_items = cart.total_items;
            const items = cart.items;
            $(".cart_count").text(total_items);
            updateProductDetailsPage(items);
            loadingShoppingCart(cart);
            loadingShoppingAddress(cart);
            loadingCheckoutPage(cart);
        }
    }

    function loadCustomerCart() {
        axios
            .post("/product/cart")
            .then((response) => {
                const resData = response.data;
                if (!_.isEmpty(resData)) {
                    renderCustomerCart(resData.cart);
                }
            })
            .catch((errors) => {
                console.log("errors", errors);
            });
    }

    setTimeout(() => {
        loadCustomerCart();
    }, 600);

    function updateCustomerCart(item_id, qty) {
        axios
            .post("/product/update-cart", {
                id: item_id,
                qty: qty,
            })
            .then((response) => {
                const resData = response.data;
                if (!_.isEmpty(resData)) {
                    renderCustomerCart(resData.cart);
                }
            })
            .catch((errors) => {
                console.log("errors", errors);
            });
    }

    // ********** end function ***************

    body.on("click", ".btnAddToCart", function (event) {
        event.preventDefault();
        let product_id = $(this).attr("data-id");
        let quantity = $("input[name=quantity]").val();
        axios
            .post("/product/add-to-cart", {
                id: product_id,
                quantity: quantity,
            })
            .then((response) => {
                const resData = response.data;
                if (!_.isEmpty(resData)) {
                    renderCustomerCart(resData.cart);
                }
            });
    })
        .on("click", ".btn-minus", function (event) {
            event.preventDefault();
            const inputGroup = $(this)
                .closest(".input-group")
                .find(".quantity");
            const item_id = inputGroup.attr("data-item-id");
            let quantity = Number(inputGroup.val());
            if (quantity > 1) {
                quantity = quantity - 1;
                updateCustomerCart(item_id, quantity);
            }
            inputGroup.val(quantity);
        })
        .on("click", ".btn-plus", function (event) {
            event.preventDefault();
            const inputGroup = $(this)
                .closest(".input-group")
                .find(".quantity");
            const item_id = inputGroup.attr("data-item-id");
            let quantity = Number(inputGroup.val());
            if (quantity > 0) {
                quantity = quantity + 1;
                updateCustomerCart(item_id, quantity);
            }
            inputGroup.val(quantity);
        })
        .on("submit", "#save-shipping-address", function (event) {
            event.preventDefault();
            const data = $(this).serialize();
            axios
                .post("/save-shipping-address", data)
                .then((response) => {
                    const resData = response.data;
                    if (!_.isEmpty(resData)) {
                        renderCustomerCart(resData.cart);
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .then(() => {
                   window.location.href = "/checkout";
                });
        });
        
})(jQuery);
